@import url('https://fonts.googleapis.com/css?family=Barlow');

.App-logo {
    height: 20vmin;
    pointer-events: none;
}

.App-header {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #282c34;
}

.App-link {
    color: #e10a0a;
    text-decoration: none;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

a {
    color: #e10a0a;
    text-decoration: none;
}

.value {
    margin-bottom: 40px;
    font-size: 14px;
}

.button-container .MuiButton-label {
    min-width: 109px;
}

.button-container {
    padding: 24px 0;
}

.button-container .grid-item {
    padding: 0 !important;
}

.account-space__content {
    margin: 0;
    min-height: calc(100vh - 90px - 75px); /*100 viewpoint height - header - footer */
}